import React from "react";
import Card from "./Card";
import { motion } from "framer-motion";
import "./Deck.css";
import { isMobile } from "is-mobile";

const Deck = ({ cards, handleCardDrop }) => {
  const firstIndexRotation = cards.length > 1 ? -(cards.length-1)/2 * (isMobile() ? 0.4 : 0.6) : 0;

  return (
    <motion.div className="deck" key={"deck"}>
      {cards.map((card, index) => {
        const rotation = -((cards.length-1)/2 - index) * (isMobile() ? 0.4 : 0.6);

        return (
          <Card
            key={card.id}
            index={index}
            cardBack="/assets/tarot/back.png"
            cardFront={card.front}
            cardId={card.id}
            handleCardDrop={handleCardDrop}
            isOnDeck={true}
            isFlipped={false}
            rotationOnDeck={rotation}
            firstIndexRotation={firstIndexRotation}
          />
        );
      })}
    </motion.div>
  );
};

export default Deck;


// Indiviual Tarot card game
import React, { useState, useContext, useEffect } from "react";
import { isMobile } from "is-mobile";
import { motion } from "framer-motion";
import { GameStateContext } from "../contexts/GameState.context";
import { containsPoint } from "../utils/cursor";
import useSound from "use-sound";
import { layouts } from "../utils/constants";
import DRAG_END_SOUND from "../assets/audio/drag_end.wav";
import FLIP_CARD_SOUND from "../assets/audio/flip_card.wav";
import CardInner from "./CardInner";
import { HoverCard, HoverCardContent, HoverCardTrigger } from './ui/hover-card';
import Content from './side-banner-card/Content';
import { tarotDeck } from '../utils/constants';

const Card = ({ index, cardBack, cardFront, cardId, handleCardDrop, isOnDeck, currentTopic, isFlipped, rotationOnDeck, firstIndexRotation, isMoreThanThreeTopics }) => {
  const [isFront, setIsFront] = useState(isFlipped);
  const [isDragging, setIsDragging] = useState(false); // This is to prevent card flipping when dragging
  // const [isOnDeck, setIsOnDeck] = useState(true); //TODO: this is now set from parent, but should be fixed later
  const [randomRotation] = useState(() => isMobile() || isMoreThanThreeTopics ? (Math.random() * 5 - 2.5) : (Math.random() * 10 - 5));
  // const { name, keywords } = tarotDeck.find(c => c.id === cardId);

  // Access the refs from the context
  const { topicsRef, hoveredTopic, setHoveredTopic, sideBannerRef, isSideBannerHovered, setIsSideBannerHovered, layoutKey, setIsFlippedDragging } = useContext(GameStateContext);

  const [playDragEndSound] = useSound(DRAG_END_SOUND, {
    volume: 0.4
  });
  const [playFlipCardSound] = useSound(FLIP_CARD_SOUND, {
    volume: 0.8
  });

  const cardSpreadHandlerDiameter = 2000;

  const cardVariants = {
    initial: {  
      y: 300,
    },
    initialOnTopic: {  
      y: 10,
      scale: 1.2,
      boxShadow: "10px 10px 1px rgba(0, 0, 0, 0.2)",
      zIndex: 50,
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      }
    },
    animateOnTopic: {
      rotate: randomRotation,
      y: 0,
      scale: 1,
      width: isMoreThanThreeTopics && 84,
      left: isMoreThanThreeTopics && "calc(50% - (84px / 2))",
      zIndex: index + 1,
      boxShadow: "none",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      }
    },
    exit: {
      y: 300,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: "easeInOut",
      }
    }
  }

  const cardSpreadHandlerVariants = {
    initial: {
      rotate: firstIndexRotation,
    },
    animate: {
      rotate: rotationOnDeck,
      x: 0,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: "easeIn",
      }
    },
    exit: {
      rotate: firstIndexRotation,
      transition: {
        duration: 0.5,
        ease: "easeIn",
      }
    },
    dragging: {
      rotate: 0,
      x: `calc(sin(${rotationOnDeck}deg) * (${cardSpreadHandlerDiameter}px / 2 - 60px))`,
      transition: {
        duration: 0,
      },
    }
  }

  const handleFlip = (e) => {
    if (isOnDeck) {
      return;
    }
    if (isFront) {
      return;
    }

    setIsFront(true); // It should be flipped once only
    playFlipCardSound();
  };

  function handleDragStart() {
    setIsDragging(true);
    if (isFront) {  
      setIsFlippedDragging(true);
    }
  }

  function handleDrag(event, info) {
    const { point } = info;

    if (sideBannerRef.current && containsPoint(sideBannerRef.current, point.x, point.y) && isFront) {
      setIsSideBannerHovered(true);
      setHoveredTopic(null);
      return;
    } else {
      setIsSideBannerHovered(false);
    }

    if (topicsRef.current) {
      console.log("topicsRef.current", topicsRef.current);
      let items = layouts[layoutKey].items;
      for (let i = 0; i < items.length; i++) {
        if (containsPoint(topicsRef.current[i], point.x, point.y)) {
          setHoveredTopic(items[i]);
          return;
        }
      }
    }
  }

  function handleDragEnd(event, info) {
    setIsDragging(false);
    
    if (!!hoveredTopic || isSideBannerHovered) {
      handleCardDrop({ id: cardId, front: cardFront, isFront, currentTopic: !isOnDeck && currentTopic }, hoveredTopic);
      setHoveredTopic(null);
      // setIsOnDeck(false); //TODO: this is now set from parent, but should be fixed later
    }
    
    setIsSideBannerHovered(false);
    playDragEndSound();
    setIsFlippedDragging(false);
  }

  return (
    <>
      {/* <HoverCard open={isFlipped ? null : false}>
        <HoverCardTrigger> */}
          <motion.div className="card-spread-handler"
            style={{
              position: "absolute",
              height: isOnDeck && cardSpreadHandlerDiameter,
              zIndex: isDragging ? 1000 : index + 1,
              // rotate: isDragging ? 0 : rotationOnDeck,
              // x: isDragging ? `calc(sin(${rotationOnDeck}deg) * (${cardSpreadHandlerDiameter}px / 2 - 60px))` : 0,
            }}
            variants={cardSpreadHandlerVariants}
            initial="initial"
            animate={isDragging ? "dragging" : "animate"}
            exit="exit"
          >
            <motion.div
              className="card"
              onTap={!isDragging && handleFlip}
              variants={cardVariants}
              initial={isOnDeck ? "initial" : "initialOnTopic"}
              animate={isOnDeck ? "animate" : "animateOnTopic"}
              exit={isOnDeck && "exit"}
              style={{
                perspective: 1000,
                cursor: "pointer",
                left: "calc(50% - (var(--card-width) / 2))",
                // rotate: !isOnDeck && randomRotation,
                zIndex: index + 1,
                width: "var(--card-width)",
                aspectRatio: "3/5",
              }}
              whileDrag={{
                scale: 1.25,
                rotate: 5,
                boxShadow: "10px 10px 1px rgba(0, 0, 0, 0.2)",
                cursor: "grabbing",
                zIndex: 1000,
                // transition: "0s",
              }}
              drag={true}
              dragElastic={1}
              dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
              onDragStart={() => {
                handleDragStart();
              }}
              onDrag={(event, info) => {
                handleDrag(event, info);
              }}
              onDragEnd={(event, info) => handleDragEnd(event, info)}
              whileHover={isOnDeck && {
                rotate: -5,
                x: -25,
                y: -35,
                transition: {
                  duration: 0.22,
                  ease: "easeInOut",
                }
              }}
            >
              <CardInner 
                cardId={cardId}
                isFlipped={isFlipped}
                isFront={isFront}
                cardBack={cardBack}
                cardFront={cardFront}
                rotation={randomRotation}
              />
            </motion.div>
          </motion.div>
        {/* </HoverCardTrigger>
        <HoverCardContent className="w-80" side="right" align="start" sideOffset={120/2} openDelay={200}>
          <Content title={name} keywords={keywords} />
        </HoverCardContent>
      </HoverCard> */}
    </>
  );
};

export default React.memo(Card);
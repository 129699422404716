import React, { useState, useEffect } from "react";
import { GameStateProvider } from "./contexts/GameState.context";
import Layout from "./components/Layout";

import Loading from "./components/Loading";
import { tarotDeck } from "./utils/constants";

import { loadImage } from "./utils/assets";
import "./styles/globals.css";
import "./App.css";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadAssets = async () => {

      const assets = tarotDeck.map(card => card.front);

      const loadPromises = assets.map(src => loadImage(src));
      await Promise.all(loadPromises);
    };

    const minimumLoadingTime = 2000; // 2 seconds

    const startLoading = async () => {
      const startTime = Date.now();
      await loadAssets();
      const elapsedTime = Date.now() - startTime;
      const remainingTime = minimumLoadingTime - elapsedTime;

      if (remainingTime > 0) {
        setTimeout(() => setIsLoading(false), remainingTime);
      } else {
        setIsLoading(false);
      }
    };

    startLoading();
  }, []);

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="App">
      {/* {isMobile() ? <MobileViewPlaceHolder /> : (
        <GameStateProvider>
          <Layout />
        </GameStateProvider>
      )} */}
      <GameStateProvider>
        <Layout />
      </GameStateProvider>
    </div>
  );
}

export default App;
import React from 'react';

const Content = ({title, keywords}) => {
  return (
    <div className="flex flex-col space-y-2">
      <h1 className="text-lg font-bold">{title}</h1>
      <div className="flex flex-col space-y-2 justify-start items-start">
        {keywords.map((keyword, index) => (
          <p key={index} className="pl-2">&#9758; {keyword}</p>
        ))}
      </div>
    </div>
  );
};

export default Content;
import * as React from "react"
import { isMobile } from "is-mobile";

import { Button } from "./ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "./ui/dialog"

export default function ConfirmDialog({children, title, description, onConfirm}) {
  const [open, setOpen] = React.useState(false)

  const handleConfirm = () => {
    onConfirm()
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="grid items-start gap-4">
          <div>
            {description}
          </div>
          {isMobile() && <Button onClick={handleConfirm}>Xác nhận</Button>}
        </div>
        <DialogFooter>
          {!isMobile() && <Button onClick={handleConfirm}>Xác nhận</Button>}
          <DialogClose asChild>
            <Button variant="outline">Huỷ</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
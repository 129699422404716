import React from "react";
import { Button } from "./ui/button";
import { Shuffle } from "lucide-react";

const ShuffleButton = ({ onClick, isShuffling, buttonDisabled }) => {
  return (
    <Button
      disabled={buttonDisabled}
      onClick={onClick}
    >
      {isShuffling ? "Dừng xào bài" : "Xào bài"} <Shuffle />
    </Button>
  );
};

export default ShuffleButton;
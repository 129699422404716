import React, { useState, useEffect, useContext } from "react";
import { AnimatePresence } from "framer-motion";
import useSound from "use-sound";
import { isMobile } from "is-mobile";
import { RotateCcw } from "lucide-react";

import { Button } from "./ui/button";
import Deck from "./Deck";
import Topic from "./Topic";
import { layouts, tarotDeck } from "../utils/constants";
import "./Game.css";
import SideBanner from "./SideBanner";
import SideBannerOpenButton from "./SideBannerOpenButton";
import LayoutButton from "./LayoutButton";
import ShuffleButton from "./ShuffleButton";
import ShuffleCardAnimation from "./ShuffleCardAnimation";
import ConfirmDialog from "./ConfirmDialog";
import { GameStateContext } from "../contexts/GameState.context";

import SPREAD_CARD_SOUND from "../assets/audio/spread_card.wav";
import SHUFFLE_CARD_SOUND from "../assets/audio/shuffle.wav";
import HOVER_SOUND from "../assets/audio/hover_topic.wav";
const topicNumberMap = {
  Past: 1,
  Present: 2,
  Future: 3
}

const Game = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [shuffledDeck, setShuffledDeck] = useState([...tarotDeck].sort(() => Math.random() - 0.5));
  const [topicCards, setTopicCards] = useState(layouts.PAST_PRESENT_FUTURE.items.map(e => ({
    topic: e,
    cards: [],
  })));
  const [sideBannerCards, setSideBannerCards] = useState([]);
  const [isShuffling, setIsShuffling] = useState(false);

  const { isSideBannerHovered, layoutKey, setLayoutKey, hoveredTopic } = useContext(GameStateContext);
  const [playSpreadCardSound] = useSound(SPREAD_CARD_SOUND, {
    volume: 5,
  });
  const [playShuffleCardSound, {stop : stopShuffleCardSound, duration: shuffleSoundDuration}] = useSound(SHUFFLE_CARD_SOUND, {
    volume: 1,
    loop: true,
  });
  const [playHoverSound] = useSound(HOVER_SOUND, {
    volume: 1,
  });

  useEffect(() => {
    if (!hoveredTopic) {
      return;
    }
    playHoverSound({ playbackRate: 1 + 0.1 * topicNumberMap[hoveredTopic] }); //TODO: this is hardcoded, should be dynamic. And the sound too big compared to the others
  }, [hoveredTopic]);

  const handleClickShuffleButton = () => {
    setButtonDisabled(true);
    if (isShuffling) {
      setIsShuffling(false);
      stopShuffleCardSound();
      setTimeout(() => {
        setButtonDisabled(false);
        playSpreadCardSound();
      }, 1000);
      return;
    }
    playSpreadCardSound();
    setIsShuffling(true);
    setTimeout(() => {
      setButtonDisabled(false);
      playShuffleCardSound({playbackRate: shuffleSoundDuration/1240}); // 1240 is the duration of the shuffle animation in milliseconds
    }, 1000);

    // If topics have cards, shuffle current deck, otherwise shuffle full deck
    shuffleDeck(shuffledDeck);
  };

  function shuffleDeck(deck) {
    const shuffled = [...deck].sort(() => Math.random() - 0.5);
    setShuffledDeck(shuffled);
  }

  // useEffect(() => {
  //   shuffleDeck(tarotDeck);
  // }, []);

  const handleDrop = (card, position) => {
    if (isSideBannerHovered) {
      setSideBannerCards((prevSideBannerCards) => [...prevSideBannerCards, card]);
      setTopicCards((prevTopicCards) => {
        // const updatedTopicCards = {
        //   ...prevTopicCards,
        //   [card.currentTopic]: [...prevTopicCards[card.currentTopic].filter((c) => c.id !== card.id)],
        // };
        const updatedTopicCards = prevTopicCards.map(e => ({
          ...e,
          cards: e.cards.filter((c) => c.id !== card.id),
        }));
        return updatedTopicCards;
      });
      return;
    }

    // Remove card from shuffledDeck
    if (!card.currentTopic) {
      setShuffledDeck((prevShuffledDeck) =>
        prevShuffledDeck.filter((c) => c.id !== card.id)
      );
    } else { 
      if (card.currentTopic === position) {
        return;
      }
      setTopicCards((prevTopicCards) => {
        // const updatedTopicCards = {
        //   ...prevTopicCards,
        //   [card.currentTopic]: [...prevTopicCards[card.currentTopic].filter((c) => c.id !== card.id)],
        // };
        const updatedTopicCards = prevTopicCards.map(e => ({
          ...e,
          cards: e.cards.filter((c) => c.id !== card.id),
        }));
        return updatedTopicCards;
      });
    }
    // Add card to the corresponding topic
    setTopicCards((prevTopicCards) => {
      const updatedTopicCards = prevTopicCards.map(e => 
        e.topic === position ? { ...e, cards: [...e.cards, card] } : e
      );
      return updatedTopicCards;
    });
  };

  function handleChangeLayout(value) {
    setLayoutKey(value);
    shuffleDeck(tarotDeck);
    setTopicCards(layouts[value].items.map(e => ({
      topic: e,
      cards: [],
    })));
    setSideBannerCards([]);
  }

  function resetGame() {
    setShuffledDeck(tarotDeck);
    setTopicCards(layouts[layoutKey].items.map(e => ({
      topic: e,
      cards: [],
    })));
    setSideBannerCards([]);
  }

  return (
    <div className="game">
      {!isMobile() && <SideBanner sideBannerCards={sideBannerCards} />}
      <div className="main-content">
        {isMobile() && <SideBannerOpenButton sideBannerCards={sideBannerCards}/>}
        <div className={`flex flex-col absolute z-50 ${isMobile() ? "top-4 right-4" : "top-8 right-8"}`} >
          <LayoutButton handleChangeLayout={handleChangeLayout} />
          <ConfirmDialog onConfirm={resetGame} title="Phiên bài mới" description="Bạn có chắc chắn muốn bắt đầu một phiên bài mới?">
            <Button className="mt-2" size={isMobile() ? "icon" : "default"}>
              {!isMobile() && "Phiên bài mới"} <RotateCcw/>
            </Button>
          </ConfirmDialog>
        </div>
        <div className={`flex flex-row flex-wrap justify-center ${isMobile() ? "my-4 flex-1 items-end content-end" : "mt-10 mb-5 items-start"} ${topicCards.length > 3 && isMobile() ? "flex-[2_2_0%]" : "flex-1"}`}>
          <AnimatePresence mode="wait" initial={false}>
            {topicCards.map((topicCard, index, array) => (<>
                {(index % 3 === 0 && index !== 0) && <div className={`w-full ${isMobile() ? "mt-1" : "h-2"}`}/>}
                <Topic
                  key={`topic-${topicCard.topic}`}
                  topic={topicCard.topic}
                  cards={topicCard.cards}
                  handleCardDrop={handleDrop}
                  index={index}
                  isMoreThanThree={array.length > 3}
                />
              </>
            ))}
          </AnimatePresence>
        </div>
        <ShuffleButton 
          onClick={handleClickShuffleButton} 
          isShuffling={isShuffling} 
          buttonDisabled={buttonDisabled}
        />
        <div className="flex flex-1 justify-center">
          <AnimatePresence mode="wait" initial={false}>
            {isShuffling ? 
              <ShuffleCardAnimation 
                key="shuffle-card-animation" 
                deckCount={18} // When cards get fewer, animation not smooth
                isShuffling={isShuffling}
              /> : 
              <Deck
                key="deck"
                cards={shuffledDeck}
                handleCardDrop={handleDrop}
              />
            }
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Game;

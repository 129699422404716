import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { isMobile } from "is-mobile";
import { Plus } from 'lucide-react';
import './SideBanner.css';
import { GameStateContext } from '../contexts/GameState.context';
import DesktopSideBannerCard from './side-banner-card/DesktopSideBannerCard';
import MobileSideBannerCard from './side-banner-card/MobileSideBannerCard';

const SideBanner = ({ sideBannerCards }) => {
  const { sideBannerRef, isSideBannerHovered } = useContext(GameStateContext);
  return (
    <div className={`side-banner ${isMobile() ? "p-2 w-60 border-none" : "p-5"}`} ref={isMobile() ? null : sideBannerRef} >
      {sideBannerCards.map((card) => (
        isMobile() ? <MobileSideBannerCard key={card.id} card={card} /> : <DesktopSideBannerCard key={card.id} card={card} />
      ))}
      <motion.button className="add-button" layoutId="side-banner-add-button"><Plus color="#ccc"/></motion.button>
      <AnimatePresence>
        {(isSideBannerHovered || (isMobile() && sideBannerCards.length === 0)) && 
          <motion.div className="add-button-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <motion.button className="add-button open" layoutId="side-banner-add-button">
                <Plus size={36}/>
                <div className="mt-2">Kéo thả bài đã lật vào đây</div>
            </motion.button>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
};

export default SideBanner;

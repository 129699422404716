import React, { useContext } from "react";
import { motion } from "framer-motion";
import Card from "./Card";
import './Topic.css';
import { GameStateContext } from "../contexts/GameState.context";
import isMobile from "is-mobile";

const Topic = React.forwardRef(({ topic, cards, handleCardDrop, index, isMoreThanThree }, ref) => {
  const { topicsRef, hoveredTopic } = useContext(GameStateContext);

  return (
    <motion.div
      ref={(el) => {topicsRef.current[index] = el}}
      className={`topic ${isMoreThanThree && "w-24"}`}
      transition={{ duration: 0.3 }}
      layout
      initial={{ opacity: 0, x: 50, scale: 0.3 }}
      animate={{ opacity: 1, x: 0, scale: 1 }}
      exit={{ opacity: 0, x: -50, scale: 0.5 }}
    >
      <div className={`topic-cards w-full ${isMobile() || isMoreThanThree ? "aspect-[3/5] p-2" : "aspect-[2/3] p-5"}`}
        style={{
          border: hoveredTopic === topic ? '1px solid #7f4ee7' : '1px solid #ccc',
          backgroundColor: hoveredTopic === topic ? '#d8cfe3' : '#ededed',
        }}
      >
        {cards.map((card, index) => (
          <Card
            key={card.id}
            cardBack="/assets/tarot/back.png"
            cardFront={card.front}
            cardId={card.id}
            marginRight={0}
            isOnDeck={false}
            handleCardDrop={handleCardDrop}
            index={index}
            currentTopic={topic}
            isFlipped={!!card.isFront}
            isMoreThanThreeTopics={isMoreThanThree}
            // index={index} //TODO: use this to calculate random rotation
          />
        ))}
      </div>
      <div className={`font-semibold text-center ${isMobile() ? "text-sm" : `text-base ${!isMoreThanThree && "mt-2"}`} `}>{topic}</div>
    </motion.div>
  );
});

export default Topic;
import React from "react";
import { HelpCircle, ChevronRight } from "lucide-react";
import { isMobile } from "is-mobile";

import { Button } from "./ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose
} from "./ui/dialog"
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "./ui/tooltip"
import { introductionVideo } from "../utils/constants";

const WelcomeDialog = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <div className={`fixed bottom-1/4 right-${isMobile() ? "4" : "8"} z-50`}>
                <Button onClick={() => setIsOpen(true)} variant="outline" size="icon"><HelpCircle /></Button>
              </div>
            </TooltipTrigger>
            <TooltipContent side="left">
              Xem video hướng dẫn
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[560px] flex flex-col items-center">
        <DialogHeader>
          <DialogTitle className="text-2xl">Chào mừng bạn đến với Tarotx.io 🎉</DialogTitle>
        </DialogHeader>
        {/* <img src={welcomeImage} alt="welcome" className="w-80 h-60 object-cover" /> */}
        <iframe src={isMobile() ? introductionVideo.mobile : introductionVideo.desktop} 
          title="Video hướng dẫn" 
          className={`w-full  object-cover ${isMobile() ? "aspect-[2/3]" : "aspect-video"}`} 
        />
        <DialogFooter className="flex justify-center items-center">
          <div className="w-full flex justify-center">
            <DialogClose asChild>
              <Button>Bắt đầu <ChevronRight /></Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeDialog;
import React from 'react';
import Header from './Header';
import Game from './Game';
import './Layout.css';
import WelcomeDialog from './WelcomeDialog';

const Layout = () => {
  return (
    <div className="layout">
      <WelcomeDialog/>
      <Header />
      <Game />
    </div>
  );
};

export default Layout;

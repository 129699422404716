import React, { useContext } from "react";
import { isMobile } from "is-mobile";
import { ChevronDown } from "lucide-react";
import { GameStateContext } from "../contexts/GameState.context";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { layouts } from "../utils/constants";

const LayoutButton = ({handleChangeLayout}) => {
  const { layoutKey } = useContext(GameStateContext);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size={isMobile() ? "icon" : "default"}>{!isMobile() && "Layouts"} <ChevronDown /></Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuLabel>Chọn layout</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup value={layoutKey} onValueChange={handleChangeLayout}>
          {Object.values(layouts).map((layout) => (
            <DropdownMenuRadioItem key={layout.value} value={layout.value}>{layout.name}</DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LayoutButton;
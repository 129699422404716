import React from "react";
import { ArrowRightFromLine, Plus } from "lucide-react";
import { Drawer, DrawerTrigger, DrawerContentLeft, DrawerTitle } from "./ui/drawer";
import { motion, AnimatePresence } from "framer-motion";
import { GameStateContext } from "../contexts/GameState.context";
import SideBanner from "./SideBanner";

const SideBannerOpenButton = ({ sideBannerCards }) => {
  const { sideBannerRef, isSideBannerHovered, isFlippedDragging } = React.useContext(GameStateContext);
  const variants = {
    default: {
      width: 36,
    },
    open: {
      width: 100,
      height: 100,
    },
    hover: {
      background: "linear-gradient(90deg,#4c27f0,#7f1ae2)",
      width: 100,
      height: 100,
      color: "white",
    },
  }
  return <div className={`flex flex-col absolute z-50 top-4 left-0`} >
    <Drawer direction="left">
      <DrawerTrigger asChild>
        <motion.button className="h-20 rounded-r-lg border border-input bg-background shadow-sm inline-flex items-center justify-center text-sm font-medium [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0" 
          ref={sideBannerRef}
          variants={variants}
          style={{
            background: isSideBannerHovered && isFlippedDragging ? "linear-gradient(90deg,#4c27f0,#7f1ae2)" : "white",
          }}
          animate={isFlippedDragging ? (
            isSideBannerHovered ? "hover" : "open"
          ) : "default"}
        >
          <AnimatePresence initial={false} mode="popLayout">
            {isFlippedDragging ? (
              <motion.div key="plus" initial={{ opacity: 0, rotate: 180 }} animate={{ opacity: 1, rotate: 0 }} exit={{ opacity: 0, rotate: 180 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
                <Plus />
              </motion.div>
            ) : (
              <motion.div key="arrow" initial={{ opacity: 0, rotate: -180 }} animate={{ opacity: 1, rotate: 0 }} exit={{ opacity: 0, rotate: -180 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
                <ArrowRightFromLine />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.button>
      </DrawerTrigger>
      <DrawerContentLeft>
        <DrawerTitle className="hidden">Nhấp vào lá bài để xem ý nghĩa</DrawerTitle>
        <SideBanner sideBannerCards={sideBannerCards} />
      </DrawerContentLeft>
    </Drawer>
  </div>;
};

export default SideBannerOpenButton;
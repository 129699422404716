export const tarotDeck = [
  // Major Arcana
  { id: 0, front: "/assets/tarot/major_0_the_fool.png", meaning: "The Fool: New beginnings.", name: "0 - The Fool", 
    keywords: ["Khởi Đầu", "Tự Nguyện", "Tự Phát", "Niềm Tin", "Điên Rồ"] },
  { id: 1, front: "/assets/tarot/major_1_the_magician.png", meaning: "The Magician: Manifestation.", name: "I - The Magician", 
    keywords: ["Hành Động", "Nhận Thức Có Ý Thức", "Tập Trung", "Quyền Lực"] },
  { id: 2, front: "/assets/tarot/major_2_the_high_priestess.png", meaning: "The High Priestess: Intuition.", name: "II - The High Priestess", 
    keywords: ["Thụ Động", "Vô Thức", "Tiềm Năng", "Bí Ẩn"] },
  { id: 3, front: "/assets/tarot/major_3_the_empress.png", meaning: "The Empress: Something.", name: "III - The Empress", 
    keywords: ["Nuôi Nấng", "Sung Túc, Trù Phú", "Tri Giác", "Thiên Nhiên"] },
  { id: 4, front: "/assets/tarot/major_4_the_emperor.png", meaning: "The Emperor: Something.", name: "IV - The Emperor", 
    keywords: ["Làm Cha", "Cấu Trúc", "Nắm Quyền", "Luật Lệ"] },
  { id: 5, front: "/assets/tarot/major_5_the_hierophant.png", meaning: "The Hierophant: Something.", name: "V - The Hierophant", 
    keywords: ["Giáo Dục", "Hệ Thống Niềm Tin", "Tiếp nhận", "Nhận dạng nhóm"] },
  { id: 6, front: "/assets/tarot/major_6_the_lovers.png", meaning: "The Lovers: Something.", name: "VI - The Lovers", 
    keywords: ["Mối Quan Hệ", "Bản Năng Giới Tính", "Niềm Tin Cá Nhân", "Giá Trị"] },
  { id: 7, front: "/assets/tarot/major_7_the_chariot.png", meaning: "The Chariot: Something.", name: "VII - The Chariot", 
    keywords: ["Chiến Thắng", "Ước Muốn", "Tự Khẳng Định", "Khó Kiểm Soát"] },
  { id: 8, front: "/assets/tarot/major_8_the_strength.png", meaning: "Strength: Something.", name: "VIII - Strength", 
    keywords: ["Sức Mạnh", "Nhẫn Nại", "Lòng Trắc Ẩn", "Kiểm Soát Mềm Mỏng"] },
  { id: 9, front: "/assets/tarot/major_9_the_hermit.png", meaning: "The Hermit: Something.", name: "IX - The Hermit", 
    keywords: ["Nội Quan", "Tìm Kiếm", "Dẫn Dắt", "Đơn Độc"] },
  { id: 10, front: "/assets/tarot/major_10_the_wheel_of_fortune.png", meaning: "Wheel of Fortune: Something.", name: "X - Wheel of Fortune", 
    keywords: ["Định Mệnh", "Điểm Bùng Phát", "Sự Biến Chuyển", "Tầm Nhìn Cá Nhân"] },
  { id: 11, front: "/assets/tarot/major_11_the_justice.png", meaning: "Justice: Something.", name: "XI - Justice", 
    keywords: ["Công Lý", "Trách Nhiệm", "Quyết Định", "Nguyên Nhân – Kết Quả"] },
  { id: 12, front: "/assets/tarot/major_12_the_hanged_man.png", meaning: "The Hanged Man: Something.", name: "XII - The Hanged Man", 
    keywords: ["Bỏ Qua", "Đảo Ngược", "Tạm Dừng", "Hy Sinh"] },
  { id: 13, front: "/assets/tarot/major_13_the_death.png", meaning: "Death: Something.", name: "XIII - Death", 
    keywords: ["Kết Thúc", "Thay Đổi", "Loại Bỏ", "Tác Động Tuyệt Đối"] },
  { id: 14, front: "/assets/tarot/major_14_the_temperance.png", meaning: "Temperance: Something.", name: "XIV - Temperance", 
    keywords: ["Chừng Mực", "Cân Bằng", "Sức Khỏe", "Kết Hợp"] },
  { id: 15, front: "/assets/tarot/major_15_the_devil.png", meaning: "The Devil: Something.", name: "XV - The Devil", 
    keywords: ["Cảnh Nô Lệ", "Chủ Nghĩa Vật Chất", "Sự Ngu Dốt", "Sự Tuyệt Vọng"] },
  { id: 16, front: "/assets/tarot/major_16_the_tower.png", meaning: "The Tower: Something.", name: "XVI - The Tower", 
    keywords: ["Thay Đổi Đột Ngột", "Giải Thoát", "Sa Sút", "Mặc Khải"] },
  { id: 17, front: "/assets/tarot/major_17_the_star.png", meaning: "The Star: Something.", name: "XVII - The Star", 
    keywords: ["Hy Vọng", "Cảm Hứng", "Hào Phóng", "Yên Bình"] },
  { id: 18, front: "/assets/tarot/major_18_the_moon.png", meaning: "The Moon: Something.", name: "XVIII - The Moon", 
    keywords: ["Nỗi Sợ Hãi", "Ảo Ảnh", "Sự Tưởng Tượng", "Sự Hoang Mang"] },
  { id: 19, front: "/assets/tarot/major_19_the_sun.png", meaning: "The Sun: Something.", name: "XIX - The Sun", 
    keywords: ["Sự Khai Sáng", "Sự Vĩ Đại", "Sức Sống", "Sự Tự Tin"] },
  { id: 20, front: "/assets/tarot/major_20_the_judgement.png", meaning: "Judgement: Something.", name: "XX - Judgement", 
    keywords: ["Sự Phán Xét", "Sự Tái Sinh", "Tiếng Gọi Nội Tâm", "Sự Rửa Tội"] },
  { id: 21, front: "/assets/tarot/major_21_the_world.png", meaning: "The World: Something.", name: "XXI - The World", 
    keywords: ["Sự Hợp Nhất", "Sự Hoàn Thành Trọn Vẹn", "Sự Kết Nối", "Sự Thỏa Mãn"] },

  // Minor Arcana
  // Wands
  { id: 22, front: "/assets/tarot/minor_wands_1.png", meaning: "Wands: Ace.", name: "Ace of Wands",
    keywords: ["Sáng tạo", "Nhiệt tình", "Tự tin", "Can đảm"] },
  { id: 23, front: "/assets/tarot/minor_wands_2.png", meaning: "Wands: Two.", name: "2 of Wands",
    keywords: ["Sức mạnh bản thân", "Lòng can đảm", "Tính độc đáo/nguyên bản"] },
  { id: 24, front: "/assets/tarot/minor_wands_3.png", meaning: "Wands: Three.", name: "3 of Wands",
    keywords: ["Khám phá", "Thấy trước", "Tài lãnh đạo"] },
  { id: 25, front: "/assets/tarot/minor_wands_4.png", meaning: "Wands: Four.", name: "4 of Wands",
    keywords: ["Ăn mừng", "Tự do", "Niềm phấn khích"] },
  { id: 26, front: "/assets/tarot/minor_wands_5.png", meaning: "Wands: Five.", name: "5 of Wands",
    keywords: ["Bất đồng", "Đấu tranh", "Phiền nhiễu"] },
  { id: 27, front: "/assets/tarot/minor_wands_6.png", meaning: "Wands: Six.", name: "6 of Wands",
    keywords: ["Ca khúc khải hoàn", "Tôn vinh", "Niềm kiêu hãnh"] },
  { id: 28, front: "/assets/tarot/minor_wands_7.png", meaning: "Wands: Seven.", name: "7 of Wands",
    keywords: ["Năng nổ", "Chống cự", "Chắc chắn"] },
  { id: 29, front: "/assets/tarot/minor_wands_8.png", meaning: "Wands: Eight.", name: "8 of Wands",
    keywords: ["Hành động nhanh", "Kết thúc / dứt điểm", "Tin tức"] },
  { id: 30, front: "/assets/tarot/minor_wands_9.png", meaning: "Wands: Nine.", name: "9 of Wands",
    keywords: ["Phòng vệ", "Kiên nhẫn", "Chịu đựng"] },
  { id: 31, front: "/assets/tarot/minor_wands_10.png", meaning: "Wands: Ten.", name: "10 of Wands",
    keywords: ["Quá sức", "Gánh nặng", "Cam chịu"] },
  { id: 32, front: "/assets/tarot/minor_wands_king.png", meaning: "Wands: King.", name: "King of Wands",
    keywords: ["Sáng tạo", "Truyền cảm hứng", "Mạnh mẽ", "Có sức lôi cuốn", "Táo bạo"] },
  { id: 33, front: "/assets/tarot/minor_wands_knight.png", meaning: "Wands: Knight.", name: "Knight of Wands",
    keywords: ["Quyến rũ – Khô khan", "Tự tin – Tự mãn", "Dũng cảm – LIều lĩnh", "Mạo hiểm – Lo lắng", "Nồng nhiệt – Nóng nảy"] },
  { id: 34, front: "/assets/tarot/minor_wands_queen.png", meaning: "Wands: Queen.", name: "Queen of Wands",
    keywords: ["Hấp dẫn", "Toàn tâm", "Nhiệt huyết", "Vui vẻ", "Tự tin"] },
  { id: 35, front: "/assets/tarot/minor_wands_page.png", meaning: "Wands: Page.", name: "Page of Wands",
    keywords: ["Sáng tạo", "Truyền cảm hứng", "Mạnh mẽ", "Có sức lôi cuốn", "Táo bạo"] },

  // Cups
  { id: 36, front: "/assets/tarot/minor_cups_1.png", meaning: "Cups: Ace.", name: "Ace of Cups",
    keywords: ["Sức mạnh cảm xúc", "Trực giác", "Thân mật", "Tình yêu"] },
  { id: 37, front: "/assets/tarot/minor_cups_2.png", meaning: "Cups: Two.", name: "2 of Cups",
    keywords: ["Sự kết nối", "Đình chiến", "Sự hấp dẫn"] },
  { id: 38, front: "/assets/tarot/minor_cups_3.png", meaning: "Cups: Three.", name: "3 of Cups",
    keywords: ["Tâm trạng phấn khởi", "Tình bạn", "Cộng đồng"] },
  { id: 39, front: "/assets/tarot/minor_cups_4.png", meaning: "Cups: Four.", name: "4 of Cups",
    keywords: ["Chỉ biết mỗi bản thân", "Sự hờ hững", "Tiến vào nội tâm"] },
  { id: 40, front: "/assets/tarot/minor_cups_5.png", meaning: "Cups: Five.", name: "5 of Cups",
    keywords: ["Mất mát", "Tang thương", "Hối tiếc"] },
  { id: 41, front: "/assets/tarot/minor_cups_6.png", meaning: "Cups: Six.", name: "6 of Cups",
    keywords: ["Thiện chí", "Ngây thơ/khờ dại", "Thời thơ ấu"] },
  { id: 42, front: "/assets/tarot/minor_cups_7.png", meaning: "Cups: Seven.", name: "7 of Cups",
    keywords: ["Ước vọng", "Những lựa chọn", "Sự tiêu pha"] },
  { id: 43, front: "/assets/tarot/minor_cups_8.png", meaning: "Cups: Eight.", name: "8 of Cups",
    keywords: ["Ý nghĩa sâu thẳm – chân lý", "Tiến tới", "Mệt mỏi"] },
  { id: 44, front: "/assets/tarot/minor_cups_9.png", meaning: "Cups: Nine.", name: "9 of Cups",
    keywords: ["Hoàn thành ước nguyện", "Hài lòng", "Thỏa mãn thể chất"] },
  { id: 45, front: "/assets/tarot/minor_cups_10.png", meaning: "Cups: Ten.", name: "10 of Cups",
    keywords: ["Niềm vui", "An bình", "Gia đình"] },
  { id: 46, front: "/assets/tarot/minor_cups_king.png", meaning: "Cups: King.", name: "King of Cups",
    keywords: ["Khôn ngoan", "Trầm tĩnh", "Tài ngoại giao", "Chăm sóc", "Khoan dung"] },
  { id: 47, front: "/assets/tarot/minor_cups_knight.png", meaning: "Cups: Knight.", name: "Knight of Cups",
    keywords: ["Lãng mạn – dễ xúc động", "Giàu tưởng tượng – không thực tế", "Nhạy cảm – thất thường", "Tinh tế – quá tế nhị", "Nội tâm – nhút nhát"] },
  { id: 48, front: "/assets/tarot/minor_cups_queen.png", meaning: "Cups: Queen.", name: "Queen of Cups",
    keywords: ["Thương yêu", "Nhân hậu", "Trực giác", "Tâm lý", "Tâm linh"] },
  { id: 49, front: "/assets/tarot/minor_cups_page.png", meaning: "Cups: Page.", name: "Page of Cups",
    keywords: ["Dễ xúc động", "Trực giác", "Thân mật", "Yêu thương"] },

  // Pentacles
  { id: 50, front: "/assets/tarot/minor_pentacles_1.png", meaning: "Pentacles: Ace.", name: "Ace of Pentacles",
    keywords: ["Sức mạnh vật chất", "Sự thịnh vượng", "Tính khả thi", "Niềm tin tưởng"] },
  { id: 51, front: "/assets/tarot/minor_pentacles_2.png", meaning: "Pentacles: Two.", name: "2 of Pentacles",
    keywords: ["Cân bằng", "Linh hoạt", "Vui vẻ"] },
  { id: 52, front: "/assets/tarot/minor_pentacles_3.png", meaning: "Pentacles: Three.", name: "3 of Pentacles",
    keywords: ["Làm việc nhóm", "Lập kế hoạch", "Thành tựu/xuất sắc"] },
  { id: 53, front: "/assets/tarot/minor_pentacles_4.png", meaning: "Pentacles: Four.", name: "4 of Pentacles",
    keywords: ["Chiếm hữu", "Kiểm soát", "Sự thay đổi bị chặn đứng"] },
  { id: 54, front: "/assets/tarot/minor_pentacles_5.png", meaning: "Pentacles: Five.", name: "5 of Pentacles",
    keywords: ["Thời điểm khó khăn", "Sức khỏe kém", "Bị từ chối"] },
  { id: 55, front: "/assets/tarot/minor_pentacles_6.png", meaning: "Pentacles: Six.", name: "6 of Pentacles",
    keywords: ["Nguồn lực", "Kiến thức", "Quyền năng"] },
  { id: 56, front: "/assets/tarot/minor_pentacles_7.png", meaning: "Pentacles: Seven.", name: "7 of Pentacles",
    keywords: ["Sự đánh giá", "Phần thưởng", "Thay đổi định hướng"] },
  { id: 57, front: "/assets/tarot/minor_pentacles_8.png", meaning: "Pentacles: Eight.", name: "8 of Pentacles",
    keywords: ["Siêng năng", "Hiểu biết", "Tỉ mỉ"] },
  { id: 58, front: "/assets/tarot/minor_pentacles_9.png", meaning: "Pentacles: Nine.", name: "9 of Pentacles",
    keywords: ["Kỷ luật", "Tự lực", "Tinh tế"] },
  { id: 59, front: "/assets/tarot/minor_pentacles_10.png", meaning: "Pentacles: Ten.", name: "10 of Pentacles",
    keywords: ["Sung túc", "Bền lâu", "Tập quán/quy tắc"] },
  { id: 60, front: "/assets/tarot/minor_pentacles_king.png", meaning: "Pentacles: King.", name: "King of Pentacles",
    keywords: ["Mạnh dạn", "Lão luyện", "Đáng tin cậy", "Sự ủng hộ/hỗ trợ", "Vững chắc"] },
  { id: 61, front: "/assets/tarot/minor_pentacles_knight.png", meaning: "Pentacles: Knight.", name: "Knight of Pentacles",
    keywords: ["Kiên định – Lì lợm", "Thận trọng – Không mạo hiểm", "Kỹ lưỡng – Ám ảnh", "Thực tế – Bi quan", "Cần cù – Chăm chỉ"] },
  { id: 62, front: "/assets/tarot/minor_pentacles_queen.png", meaning: "Pentacles: Queen.", name: "Queen of Pentacles",
    keywords: ["Nuôi dưỡng", "Nhân cách cao cả", "Tính hợp lý", "Tháo vát", "Đáng tin cậy"] },
  { id: 63, front: "/assets/tarot/minor_pentacles_page.png", meaning: "Pentacles: Page.", name: "Page of Pentacles",
    keywords: ["Có sự ảnh hưởng", "Thực tế", "Thịnh vượng", "Tin tưởng/đáng tin cậy"] },

  // Swords
  { id: 64, front: "/assets/tarot/minor_swords_1.png", meaning: "Swords: Ace.", name: "Ace of Swords",
    keywords: ["Sức mạnh trí óc", "Sự thật", "Công lý", "Kiên cường"] },
  { id: 65, front: "/assets/tarot/minor_swords_2.png", meaning: "Swords: Two.", name: "2 of Swords",
    keywords: ["Ngăn chặn cảm xúc", "Sự tránh xa", "Sự bế tắc"] },
  { id: 66, front: "/assets/tarot/minor_swords_3.png", meaning: "Swords: Three.", name: "3 of Swords",
    keywords: ["Đau khổ", "Cô đơn", "Phản bội"] },
  { id: 67, front: "/assets/tarot/minor_swords_4.png", meaning: "Swords: Four.", name: "4 of Swords",
    keywords: ["Nghỉ ngơi", "Suy tính", "Chuẩn bị trong lặng lẽ"] },
  { id: 68, front: "/assets/tarot/minor_swords_5.png", meaning: "Swords: Five.", name: "5 of Swords",
    keywords: ["Tự lợi", "Bất hoà", "Mất danh dự một cách công khai"] },
  { id: 69, front: "/assets/tarot/minor_swords_6.png", meaning: "Swords: Six.", name: "6 of Swords",
    keywords: ["Buồn tẻ", "Phục hồi", "Di chuyển"] },
  { id: 70, front: "/assets/tarot/minor_swords_7.png", meaning: "Swords: Seven.", name: "7 of Swords",
    keywords: ["Chạy trốn", "Thích phong cách đơn độc", "Nỗi hổ thẹn giấu kín"] },
  { id: 71, front: "/assets/tarot/minor_swords_8.png", meaning: "Swords: Eight.", name: "8 of Swords",
    keywords: ["Hạn chế", "Rối loạn", "Mất quyền lực"] },
  { id: 72, front: "/assets/tarot/minor_swords_9.png", meaning: "Swords: Nine.", name: "9 of Swords",
    keywords: ["Lo lắng", "Cảm giác tội lỗi", "Nỗi thống khổ"] },
  { id: 73, front: "/assets/tarot/minor_swords_10.png", meaning: "Swords: Ten.", name: "10 of Swords",
    keywords: ["Chạm xuống đáy", "Tâm lý bị hại", "Chịu khổ nhục"] },
  { id: 74, front: "/assets/tarot/minor_swords_king.png", meaning: "Swords: King.", name: "King of Swords",
    keywords: ["Thông minh", "Biết phân tích", "Ăn nói lưu loát", "Công bằng", "Đạo đức"] },
  { id: 75, front: "/assets/tarot/minor_swords_knight.png", meaning: "Swords: Knight.", name: "Knight of Swords",
    keywords: ["Thẳng thắn – lỗ mãng", "Có thẩm quyền – hống hách", "Sắc bén – cắt giảm", "Am hiểu – ngoan cố", "Lý trí – nhẫn tâm"] },
  { id: 76, front: "/assets/tarot/minor_swords_queen.png", meaning: "Swords: Queen.", name: "Queen of Swords",
    keywords: ["Chân thật", "Sắc sảo", "Thẳng thắn", "Vui tính", "Từng trải"] },
  { id: 77, front: "/assets/tarot/minor_swords_page.png", meaning: "Swords: Page.", name: "Page of Swords",
    keywords: ["Sử dụng lý trí", "Thật thà", "Công bằng", "Kiên cường"] },
];

export const topics = ["Past", "Present", "Future"];

export const layouts = {
  PAST_PRESENT_FUTURE: {
    name: "Past, Present, Future",
    value: "PAST_PRESENT_FUTURE",
    items: [
      "Past",
      "Present",
      "Future"
    ],
  },
  PERSONA_OBSTACLE_SOLUTION: {
    name: "Persona, Obstacle, Solution",
    value: "PERSONA_OBSTACLE_SOLUTION",
    items: [
      "Persona",
      "Obstacle",
      "Solution"
    ],
  },
  STAY_GO: {
    name: "Stay or Go",
    value: "STAY_GO",
    items: [
      "Stay",
      "Go"
    ],
  },
  HOW_TO_HANDLE_IT: {
    name: "How To Handle It",
    value: "HOW_TO_HANDLE_IT",
    items: [
      "Best way to handle this",
      "Worst way to handle this",
    ],
  },
  THREE_BLANKS: {
    name: "3 Blanks",
    value: "THREE_BLANKS",
    items: [
      "1",
      "2",
      "3",
    ],
  },
  SIX_BLANKS: {
    name: "6 Blanks",
    value: "SIX_BLANKS",
    items: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
    ],
  }
};

export const colors = {
  primary: "#5527b9",
  secondary: "#b37df6"
}

export const introductionVideo = {
  desktop: "https://www.youtube.com/embed/Y4vam_z9ljE?si=jncLBQUGqkqK1NE9", 
  mobile: "https://www.youtube.com/embed/nTGEli8R-k4?si=vb77vPm-ZxoLeZDX",
};
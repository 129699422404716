import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { tarotDeck } from '../../utils/constants';
import Content from './Content';

const MobileSideBannerCard = ({ card }) => {
  const { name, keywords } = tarotDeck.find(c => c.id === card.id);

  return (
    <Popover key={card.id}>
      <PopoverTrigger>
        <div className="flex flex-col justify-center items-center">
          <img className="w-full aspect-[3/5] object-contain border border-gray-300 rounded" src={card.front} alt={card.currentTopic} />
          <div className="text-sm text-gray-500">{card.currentTopic}</div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="max-w-80">
        <Content title={name} keywords={keywords} />
      </PopoverContent>
    </Popover>
  );
};

export default MobileSideBannerCard;

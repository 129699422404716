import React from 'react';
import { motion } from 'framer-motion';

const CardInner = ({ isFlipped, isFront, cardBack, cardFront }) => {
  const preventDefaultDrag = (e) => {
    e.preventDefault();
  };

  return (
        <motion.div
          className="card-inner"
          animate={!isFlipped && { rotateY: isFront ? 180 : 0 }}
          transition={{ duration: 0.6 }}
          style={{
            transformStyle: "preserve-3d",
            position: "relative",
          }}
        >
          {!isFlipped && <motion.img
            src={cardBack}
            alt="Tarot card back"
            draggable="false"
            onDragStart={preventDefaultDrag}
            style={{
              position: "absolute",
              backfaceVisibility: "hidden",
              width: "100%",
              left: "0",
            }}
          />}
          <motion.img
            src={cardFront}
            alt="Tarot card front"
            draggable="false"
            onDragStart={preventDefaultDrag}
            style={{
              position: "absolute",
              backfaceVisibility: "hidden",
              width: "100%",
              left: "0",
              transform: !isFlipped && "rotateY(180deg)",
            }}
          />
        </motion.div>
  );
};

export default CardInner;
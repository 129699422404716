import React, { createContext, useRef, useState } from 'react';
import { layouts } from '../utils/constants';

// Create a context
export const GameStateContext = createContext();

// Create a provider component
export const GameStateProvider = ({ children }) => {
  // Create refs for each topic
  const topicsRef = React.useRef({});

  const sideBannerRef = useRef(null);

  // State to track which topic is being hovered
  const [hoveredTopic, setHoveredTopic] = useState(null);
  const [isSideBannerHovered, setIsSideBannerHovered] = useState(false);
  const [layoutKey, setLayoutKey] = useState(layouts.PAST_PRESENT_FUTURE.value);
  const [isFlippedDragging, setIsFlippedDragging] = useState(false);

  // Context value to be provided
  const contextValue = {
    topicsRef,
    hoveredTopic,
    setHoveredTopic, // Provide a function to update the hovered topic
    sideBannerRef,
    isSideBannerHovered,
    setIsSideBannerHovered,
    layoutKey,
    setLayoutKey,
    isFlippedDragging,
    setIsFlippedDragging,
  };

  return (
    <GameStateContext.Provider value={contextValue}>
      {children}
    </GameStateContext.Provider>
  );
};

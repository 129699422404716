import React from 'react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../ui/hover-card";
import Content from './Content';
import { tarotDeck } from '../../utils/constants';

const DesktopSideBannerCard = ({ card }) => {
  const { name, keywords } = tarotDeck.find(c => c.id === card.id);
  return (
    <HoverCard key={card.id} openDelay={200}>
      <HoverCardTrigger>
        <div className="flex flex-col justify-center items-center">
          <img className="w-full aspect-[3/5] object-contain border border-gray-300 rounded" src={card.front} alt={card.currentTopic} />
          <div className="text-sm text-gray-500">{card.currentTopic}</div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="w-80" side="right" align="start">
        <Content title={name} keywords={keywords} />
      </HoverCardContent>
    </HoverCard>
  );
};

export default DesktopSideBannerCard;
